// constants
export const SET_SCROLL_POSITION = 'SET_SCROLL_POSITION';
export const ADD_SCROLL_EVENT = 'ADD_SCROLL_EVENT';
export const SET_SCROLL_DIRECTION = 'SET_SCROLL_DIRECTION';
export const RELOAD_SCROLL_EVENT = 'RELOAD_SCROLL_EVENT';
export const RELOAD_SCROLL_EVENTS = 'RELOAD_SCROLL_EVENTS';
export const TRIGGER_SCROLL_EVENTS = 'TRIGGER_SCROLL_EVENTS';
export const SET_AD_HOC_STATE = 'SET_AD_HOC_STATE';
export const SET_SCROLL = 'SET_SCROLL';
export const SET_IS_SCROLLING = 'SET_IS_SCROLLING';
export const TOGGLE_MOBILE_MENU = 'TOGGLE_MOBILE_MENU';
export const SET_ANY_STATE = 'SET_ANY_STATE';
export const SET_SITE_BASEURL = 'SET_SITE_BASEURL';
export const SITE_IS_LOADING = 'SITE_IS_LOADING';
export const TOGGLE_SEARCH = 'TOGGLE_SEARCH';
export const SET_SEARCH_IS_OPEN = 'SET_SEARCH_IS_OPEN';
export const SET_WINDOW_WIDTH = 'SET_WINDOW_WIDTH';
export const SET_IP = 'SET_IP';
export const TOGGLE_ACTIVE_DROPDOWN = 'TOGGLE_ACTIVE_DROPDOWN';
